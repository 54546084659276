/* custom scrollbar */
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #cdcdcd;
}

::-webkit-scrollbar-thumb {
    background: #95a0ad;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:active {
    background: #3a62a8;
}

.main {
    position: relative;
    z-index: 1;
    width: 100%;
}

.main-visual {
    height: 58.25rem;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    transition: all 0.5s ease-out;
}
.main-visual.section1 {
    background-image: url(../image/main/bg_main_visual01.jpg);
}
.main-visual.section2 {
    background-image: url(../image/main/bg_main_visual02.jpg);
}
.main-visual.section3 {
    background-image: url(../image/main/bg_main_visual03.jpg);
}
.visual {
    display: flex;
    flex-direction: column;
    max-width: 1320px;
    margin: 0 auto;
    padding: 20rem 1.25em 0;
    position: relative;
}
.visual-title {
    display: inline-flex;
    flex-direction: column;
    color: #FFF;
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.343;
}
.visual-title strong {
    display: inline-flex;
    font-size: 5rem;
}
.visual-text {
    color: #FFF;
    font-size: 2.125rem;
}
.visual-slider {
    display: flex;
    align-items: center;
    max-width: 1320px;
    color: #FFF;
    padding: 0 1.25em;
    margin: 5.81rem auto 0;
    gap: 0.5rem;
}
.visual-slider .cnt {
    font-size: 1.5rem;
}
.visual-slider .cnt strong {
    font-size: 2.25rem;
    font-weight: 600;
}
.visual-slider .btn-next, .visual-slider .btn-prev {
    display: block;
    content: "";
    width: 1.5rem;
    height: 2.5rem;
    background: url(../image/main/icon_arrow.svg) no-repeat center center;
}
.btn-next {
    transform: rotate(-180deg);
}

.progress-bar {
    position: relative;
    width: 23.688rem;
    height: 2px;
    margin: 4px 0 22px;
    overflow: hidden;
}
.section2 .progress-bar {
    width: 18.125rem;
}
.section3 .progress-bar {
    width: 19.875rem;
}
.progress-bar span {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: #fff;
}
.progress-bar span.on {
    width: 100%;
    transition: width 6s linear;
}

.about-section .article:nth-child(odd) {
    background: #F8F8F8;
}
.about-section .article .inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1320px;
    padding: 9.38rem 7.25rem;
    margin: 0 auto;
    position: relative;
}
.about-section .article .inner .num {
    display: inline-block;
    color: var(--2, #767676);
    font-size: 1.5rem;
    font-weight: 500;
    position: absolute;
    top: 9.38rem;
    right: 7.25rem;
}
.about-section .article.title {
    height: 32.75rem;
}
.about-section .article.title .inner {
    justify-content: center;
    align-items: center;
    padding: 9.38rem 1.25rem;
}
.about-section .article.title .title {
    width: 100%;
    color: #A6539C;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: left;
    margin-bottom: 2.5rem;
}
.about-section .article.title .title::after {
    display: none;
}
.about-section .article.title .logo {
    width: 24.9375rem;
    height: 3.25rem;
    background: url(../image/main/logo_text.svg) no-repeat center center;
    background-size: contain;
    margin-bottom: 2rem;
}
.about-section .article.title .text {
    color: var(--2, #767676);
    font-size: 1.5rem;
    line-height: 1.5;
    text-align: center;
    transform: rotate(-0.03deg);
}
.about-section .article .title {
    color: #3C3C3C;
    font-size: 2.25rem;
    margin-bottom: 1.5rem;
    position: relative;
}
.about-section .article .title strong {
    display: block;
    font-weight: 600;
}
.about-section .article .title::after {
    display: block;
    content: "";
    width: 0.75rem;
    height: 0.75rem;
    background: #A6539C;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: -1.125rem;
}
.about-section .article .text {
    color: var(--2, #767676);
    font-size: 1.375rem;
    font-weight: 500;
    margin-bottom: 3.75rem;
}

.section02 .tool-box-wrap {
    display: flex;
}
.section02 .video-box {
    position: relative;
    width: calc(100% - 13.5rem);
    margin-right: 0.75rem;
}
.section02 .video-box__item {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
}
.section02 .video-box__item.on {
    position: relative;
    z-index: 2;
    visibility: visible;
    opacity: 1;
}
.section02 .video-box__item video {
    width: 100%;
}
.section02 .tool-box {
    width: 13.5rem;
}
.section02 .tool-box__item {
    display: flex;
    border-bottom: 1px solid #cdcdcd;
}
.section02 .tool-box__item:first-child {
    border-top: 1px solid #cdcdcd;
}
.section02 .tool-box__item__btn {
    position: relative;
    width: 100%;
    height: 6.175rem;
    padding: 1.44rem 0.25rem 1.44rem 0.25rem;
    color: #767676;
    font-size: 1.25rem;
    font-weight: 500;
    text-align: right;
    letter-spacing: -0.025em;
}
.section02 .tool-box__item__btn::before {
    content: "";
    display: inline-block;
    width: 3.375rem;
    height: 3.375rem;
    background: url(../image/main/aistudio_tool.svg) no-repeat;
    background-size: 6rem;
    border: 1px solid #767676;
    border-radius: 0.375rem;
    margin-top: -25px;
    position: absolute;
    top: 50%;
    left: 0.25rem;
}
.section02 .tool-box__item.on button {
    color: #F6AB00;
}
.section02 .tool-box__item.on button::before {
    border-color: #F6AB00;
    background-color: #fff;
}
.section02 .tool-box__item.on:nth-child(1) button::before {
    background-position: -2.838rem 0;
}
.section02 .tool-box__item.on:nth-child(2) button::before {
    background-position: -2.775rem -2.938rem;
}
.section02 .tool-box__item.on:nth-child(3) button::before {
    background-position: -2.775rem -5.875rem;
}
.section02 .tool-box__item.on:nth-child(4) button::before {
    background-position: -2.775rem -8.875rem;
}
.section02 .tool-box__item.on:nth-child(5) button::before {
    background-position: -2.775rem -11.875rem;
}
.section02 .tool-box__item:nth-child(1) button::before {
    background-position: 0.163rem 0;
}
.section02 .tool-box__item:nth-child(2) button::before {
    background-position: 0.225rem -2.938rem;
}
.section02 .tool-box__item:nth-child(3) button::before {
    background-position: 0.225rem -5.875rem;
}
.section02 .tool-box__item:nth-child(4) button::before {
    background-position: 0.225rem -8.875rem;
}
.section02 .tool-box__item:nth-child(5) button::before {
    background-position: 0.225rem -11.875rem;
}

.service-section {
    height: 40.0625rem;
    background: #F8F8F8;
    position: relative;
}
.service-section .contactus {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.88rem;
    padding: 19.16rem 0 12.65rem;
}
.service-section .title {
    color: #3C3C3C;
    font-size: 2.875rem;
    text-align: center;
    position: relative;
}
.service-section .title::after {
    display: block;
    content: "";
    width: 5.63094rem;
    height: 4.59744rem;
    background: url(../image/main/logo_icon.svg) no-repeat center center;
    background-size: cover;
    position: absolute;
    left: 50%;
    top: -6.47rem;
    margin-left: -4.6rem;
}
.service-section .btn-wrap {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
}
.btn-contact, .btn-start {
    display: flex;
    padding: 0.75rem 1.5rem;
    color: var(--1, #F6AB00);
    border: 1px solid var(--2, #F6AB00);
    border-radius: 4.5rem;
}

.btn-start {
    color: var(--1, #3C3C3C);
    border-color: #3C3C3C;
}

.service-section .bg-icon {
    display: block;
    content: "";
    width: 3.375rem;
    height: 3.375rem;
    position: absolute;
    left: 50%;
    background: url(../image/main/bg_icon.png) no-repeat center 0;
}
.service-section .bg-icon.left1 {
    background-position: center 0;
    top: 6.88rem;
    margin-left: -560px;
}
.service-section .bg-icon.left2 {
    width: 4.3125rem;
    height: 4.3125rem;
    background-position: center -51px;
    top: 9.94rem;
    margin-left: -43rem;
}
.service-section .bg-icon.left3 {
    width: 3.4375rem;
    height: 3.4375rem;
    background-position: center -118px;
    top: 24.19rem;
    margin-left: -26rem;
}
.service-section .bg-icon.left4 {
    width: 5.4375rem;
    height: 5.4375rem;
    background-position: center -172px;
    top: 28.37rem;
    margin-left: -34rem;
}
.service-section .bg-icon.right1 {
    width: 3.1875rem;
    height: 3.1875rem;
    background-position: center -258px;
    top: 4.63rem;
    margin-left: 33.62rem;
}
.service-section .bg-icon.right2 {
    width: 4.125rem;
    height: 4.125rem;
    background-position: center -308px;
    top: 9.5rem;
    margin-left: 426px;
}
.service-section .bg-icon.right3 {
    width: 6.3125rem;
    height: 6.3125rem;
    background-position: center -368px;
    top: 21.37rem;
    margin-left: 36.81rem;
}
.service-section .bg-icon.right4 {
    width: 4rem;
    height: 4rem;
    background-position: center -459px;
    top: 29.81rem;
    margin-left: 33.5rem;
}

@media all and (min-width: 1921px) {
    .visual {
        max-width: 1440px;
    }
    .visual-slider {
        max-width: 1440px;
    }
    .about-section .article .inner {
        max-width: 1440px;
    }
    .section02 .tool-box__item__btn {
        height: 7.025rem;
    }
}
@media all and (max-width: 1320px) {
    .visual {
        max-width: 1180px;
    }
    .visual-slider {
        max-width: 1180px;
    }
    .about-section .article .inner {
        max-width: 1180px;
    }
    .tool .txt {
        margin: 2em 0 4em;
    }
    .tool__box {
        flex-direction: column;
        align-items: center;
    }
    .tool .video-box {
        width: 840px;
        margin-right: 0;
    }
    .tool .video-box__item {
        width: 100%;
    }
    .tool .tool-box {
        display: flex;
        flex-wrap: wrap;
        width: 840px;
    }
    .tool .tool-box__item {
        width: 280px;
        height: 72px;
        margin-top: 20px;
        border-bottom: 0;
        border-left: 1px solid #cdcdcd;
    }
    .tool .tool-box__item:first-child {
        border-top: 0;
    }
    .tool .tool-box__item:last-child {
        border-right: 1px solid #cdcdcd;
    }
    .tool .tool-box__item:nth-child(3n) {
        border-right: 1px solid #cdcdcd;
    }
    .section02 .tool-box__item__btn {
        height: 6.375rem;
    }
}
@media all and (max-width: 1180px) {
    .visual, .visual-slider {
        max-width: 1024px;
        min-width: 1024px;
    }
    .about-section .article .inner {
        max-width: 1024px;
        min-width: 1024px;
    }
    .progress-bar {
        width: 334px;
    }
    .section2 .progress-bar {
        width: 256px;
    }
    .section3 .progress-bar {
        width: 278px;
    }
    .section02 .tool-box__item__btn {
        height: 5.075rem;
    }
}