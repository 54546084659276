// 컬러 변수
$white : #ffffff;
$border : #cdcdcd;
$mycrowd-gray : #95a0ad;
$mycrowd-footergray : #7b87a0;
$mycrowd-pointblue : #09234a;
$mycrowd-darkblue : #343f55;
$mycrowd-lightblue : #457ad8;
$mycrowd-alert : #e12d4b;
$mycrowd-logo1 : #2BB6A8;
$mycrowd-logo2 : #F6AB00;
$mycrowd-logo3 : #A6539C;

/* custom scrollbar */
::-webkit-scrollbar {width: 10px;}
::-webkit-scrollbar-track {background: $border;}
::-webkit-scrollbar-thumb {background: $mycrowd-gray;border-radius: 5px;}
::-webkit-scrollbar-thumb:active {background: #3a62a8;}

// fontSize
$fz-small : 13px;
$fz-medium : 14px;
$fz-regular : 16px;
$fz-large : 18px;

// fontWeight
$fw-normal : 400;
$fw-medium : 500;
$fw-bold : 600;
