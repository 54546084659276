@charset "UTF-8";
@use './main/variables' as *;
@use './main/mixin' as *;

// main
.main {
    position: relative;
    z-index: 1;
    width: 100%;
}

// main-visual section
.main-visual {
    height: 58.25rem;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    transition: all 0.5s ease-out;

    &.section1{
        background-image: url(../image/main/bg_main_visual01.jpg);
    }
    &.section2{
        background-image: url(../image/main/bg_main_visual02.jpg);
    }
    &.section3{
        background-image: url(../image/main/bg_main_visual03.jpg);
    }

    @at-root .visual {
        display: flex;        
        flex-direction: column;
        max-width: 1320px;
        margin:  0 auto;
        padding: 20rem 1.25em 0;        
        position: relative;

        &-title{
            display: inline-flex;
            flex-direction: column;
            color: #FFF;
            font-size: 2.5rem;
            font-weight: $fw-bold;
            line-height: 1.343;
            // margin-bottom: 2rem;
            strong{
                display: inline-flex;
                font-size: 5rem;
            }
        }
        &-text{            
            color: #FFF;
            font-size: 2.125rem;
        }
        &-slider{
            display: flex;
            align-items: center;
            max-width: 1320px;
            color: #FFF;
            padding: 0 1.25em;
            margin:  5.81rem auto 0;
            gap: 0.5rem;
            .cnt{
                font-size: 1.50rem;
                strong{
                    font-size: 2.25rem;
                    font-weight: $fw-bold;
                }
            }
            %btn{
                display: block;
                content: '';
                width: 1.5rem;
                height: 2.5rem;
                background: url(../image/main/icon_arrow.svg) no-repeat center center;
            }
            @at-root .btn{
                &-prev{
                    @extend %btn;
                }
                &-next{
                    @extend %btn;
                    transform: rotate(-180deg);
                }
            }
        }
        @at-root .progress-bar{
            position: relative;
            width: 23.688rem;
            height: 2px;
            margin: 4px 0 22px;
            overflow: hidden;
            .section2 &{
                width: 18.125rem;
            }
            .section3 &{
                width: 19.875rem;
            }
    
            span {
                display: inline-block;
                position: absolute;
                top: 0;
                left: 0;
                width: 0;
                height: 100%;
                background: #fff;
                &.on {
                    width: 100%;
                    transition: width 6s linear;
                }
            }
        }
    }
}

.about-section{
    .article{
        &:nth-child(odd){
            background: #F8F8F8;
        }
        .inner{
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 1320px;
            padding: 9.38rem 7.25rem;
            margin: 0 auto;
            position: relative;
            .num{
                display: inline-block;
                color: var(--2, #767676);
                font-size: 1.5rem;
                font-weight: $fw-medium;
                position: absolute;
                top: 9.38rem;
                right: 7.25rem;
            }
        }
        &.title{
            height: 32.75rem;
            .inner{
                justify-content: center;
                align-items: center;
                padding: 9.38rem 1.25rem;
            }
            .title{
                width: 100%;
                color: $mycrowd-logo3;
                font-size: 1.5rem;
                font-weight: $fw-medium;
                text-align: left;
                margin-bottom: 2.5rem;
                &::after{
                    display: none;
                }
            }
            .logo{
                width: 24.9375rem;
                height: 3.25rem;
                background: url(../image/main/logo_text.svg) no-repeat center center;
                background-size: contain;
                margin-bottom: 2rem;
            }
            .text{
                color: var(--2, #767676);
                font-size: 1.5rem;
                line-height: 1.5;
                text-align: center;
                transform:rotate(-0.03deg);
            }
        }
        .title{
            color: #3C3C3C;
            font-size: 2.25rem;
            margin-bottom: 1.5rem;
            position: relative;
            strong{
                display: block;
                font-weight: $fw-bold;
            }
            &::after{
                display: block;
                content: '';
                width: 0.75rem;
                height: 0.75rem;
                background: $mycrowd-logo3;
                border-radius: 50%;
                position: absolute;
                top: 0;
                left: -1.125rem;
            }
        }
        .text{
            color: var(--2, #767676);
            font-size: 1.375rem;
            font-weight: $fw-medium;
            margin-bottom: 3.75rem;
        }
    }
}
.section02 {
    // 섹션2 비디오박스
    .tool-box-wrap{
        display: flex;
    }
    .video-box {
        position: relative;
        width: calc(100% - 13.5rem);
        margin-right: 0.75rem;

        &__item {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            height: 100%;
            visibility: hidden;
            opacity: 0;
            overflow: hidden;

            &.on {
                position: relative;
                z-index: 2;
                visibility: visible;
                opacity: 1;
            }

            video {
                width: 100%;
            }
        }
    }

    .tool-box {
        width: 13.5rem;
    
        &__item {
            display: flex;
            // height: 6.255rem;
            border-bottom: 1px solid $border;
        
            &:first-child {
                border-top: 1px solid $border;
            }
        
            &__btn {
                position: relative;
                width: 100%;
                height: 6.175rem;
                padding: 1.44rem 0.25rem 1.44rem 0.25rem;
                color: #767676;
                font-size: 1.25rem;
                font-weight: $fw-medium;
                text-align: right;
                letter-spacing: -0.025em;
            
                &::before {
                    content: '';
                    display: inline-block;
                    width: 3.375rem;
                    height: 3.375rem;
                    background: url(../image/main/aistudio_tool.svg) no-repeat;
                    background-size: 6rem;
                    border: 1px solid #767676;
                    border-radius: 0.375rem;
                    margin-top: -25px;
                    position: absolute;
                    top: 50%;
                    left: 0.25rem;
                }
            }
        
            &.on {
                button {
                    color: $mycrowd-logo2;
                    &::before {
                        border-color: $mycrowd-logo2;
                        background-color: #fff;
                    }
                }
                &:nth-child(1) button::before {background-position: -2.838rem 0;}
                &:nth-child(2) button::before {background-position: -2.775rem -2.938rem;}
                &:nth-child(3) button::before {background-position: -2.775rem -5.875rem;}
                &:nth-child(4) button::before {background-position: -2.775rem -8.875rem;}
                &:nth-child(5) button::before {background-position: -2.775rem -11.875rem;}
            }
            &:nth-child(1) button::before {background-position: 0.163rem 0;}
            &:nth-child(2) button::before {background-position: 0.225rem -2.938rem;}
            &:nth-child(3) button::before {background-position: 0.225rem -5.875rem;}
            &:nth-child(4) button::before {background-position: 0.225rem -8.875rem;}
            &:nth-child(5) button::before {background-position: 0.225rem -11.875rem;}
        }
    }
}

.service-section{
    height: 40.0625rem;
    background: #F8F8F8;
    position: relative;
    .contactus{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1.88rem;
        padding: 19.16rem 0 12.65rem;
    }
    .title{
        color: #3C3C3C;
        font-size: 2.875rem;
        text-align: center;
        position: relative;
        &::after{
            display: block;
            content: '';
            width: 5.63094rem;
            height: 4.59744rem;
            background: url(../image/main/logo_icon.svg) no-repeat center center;
            background-size: cover;
            position: absolute;
            left: 50%;
            top: -6.47rem;
            margin-left: -4.6rem;
        }
    }
    .btn-wrap{
        display: flex;
        justify-content: center;
        gap: 1.5rem;
        @at-root .btn-contact{
            display: flex;
            padding: 0.75rem 1.5rem;
            color: var(--1, #F6AB00);
            border: 1px solid var(--2, #F6AB00);
            border-radius: 4.5rem;
        }
        @at-root .btn-start{
            @extend .btn-contact;
            color: var(--1, #3C3C3C);
            border-color: #3C3C3C;
        }
    }
    .bg-icon{
        display: block;
        content: '';
        width: 3.375rem;
        height: 3.375rem;
        position: absolute;
        left: 50%;
        background: url(../image/main/bg_icon.png) no-repeat center 0;
        &.left1{background-position: center 0;top: 6.88rem;margin-left: -560px;}
        &.left2{width: 4.3125rem;height: 4.3125rem;background-position: center -51px;top: 9.94rem;margin-left: -43rem;}
        &.left3{width: 3.4375rem;height: 3.4375rem;background-position: center -118px;top: 24.19rem;margin-left: -26rem;}
        &.left4{width: 5.4375rem;height: 5.4375rem;background-position: center -172px;top: 28.37rem;margin-left: -34rem;}
        &.right1{width: 3.1875rem;height: 3.1875rem;background-position: center -258px;top: 4.63rem;margin-left: 33.62rem;}
        &.right2{width: 4.125rem;height: 4.125rem;background-position: center -308px;top: 9.5rem;margin-left: 426px;}
        &.right3{width: 6.3125rem;height: 6.3125rem;background-position: center -368px;top: 21.37rem;margin-left: 36.81rem;}
        &.right4{width: 4rem;height: 4rem;background-position: center -459px;top: 29.81rem;margin-left: 33.5rem;}
    }
}

@media all and (min-width:1921px) {
    .visual {
        max-width: 1440px;
        &-slider{
            max-width: 1440px;
        }
    }
    .about-section{
        .article{
            .inner{
                max-width: 1440px;
            }
        }
    }
    .section02 .tool-box__item__btn{
        height: 7.025rem;
    }
}

@media all and (max-width:1320px) {
    .visual {
        max-width: 1180px;
        &-slider{
            max-width: 1180px;
        }
    }
    .about-section{
        .article{
            .inner{
                max-width: 1180px;
            }
        }
    }

    .section02 {
        @at-root .tool {
            .txt {
                margin: 2em 0 4em;
            }
    
            &__box {
                flex-direction: column;
                align-items: center;
            }
            .video-box {
                width: 840px;
                margin-right: 0;
    
                &__item {
                    width: 100%;
                }
            }

            .tool-box {
                display: flex;
                flex-wrap: wrap;
                width: 840px;

                &__item {
                    width: 280px;
                    height: 72px;
                    margin-top: 20px;
                    border-bottom: 0;
                    border-left: 1px solid #cdcdcd;

                    &:first-child {
                        border-top: 0;
                    }

                    &:last-child {
                        border-right: 1px solid #cdcdcd;
                    }

                    &:nth-child(3n) {
                        border-right: 1px solid #cdcdcd;
                    }
                }
            }
        }
    }
    .section02 .tool-box__item__btn{
        height: 6.375rem;
    }
}

@media all and (max-width:1180px) {
    // .container{
    //     max-width: 980px;
    //     min-width: 980px;
    // }

    .visual,.visual-slider{
        max-width: 1024px;
        min-width: 1024px;
    }

    .about-section{
        .article{
            .inner{
                max-width: 1024px;
                min-width: 1024px;
            }
        }
    }

    .progress-bar{
        width: 334px;
        .section2 &{
            width: 256px;
        }
        .section3 &{
            width: 278px;
        }
    }

    .section02 .tool-box__item__btn{
        height: 5.075rem;
    }
}
